;(function($){


	$.fn.responsive_menu = function(options){
		var opts = $.extend(true, {},$.fn.responsive_menu.defaults,options);

		$(opts.menuControls.containerDescriptor).addClass(opts.menuControls.containerClass);
		$(opts.targetMenuContainerDescriptor).addClass(opts.menu.menuClass);

		if(opts.pushBody.push == true){
			$(opts.pushBody.pushContainerDescriptor).addClass(opts.pushBody.baseClass);
		}

		$(opts.menuControls.openLinkDescriptor).each(function(){
			$(this).click({options:opts},clickHandler);
		});

		$(opts.menuControls.closeLinkDescriptor).each(function(){
			$(this).click({options:opts},clickHandler);
		});

		$(document).on('touchmove',function(e){
			console.log($(opts.targetMenuContainerDescriptor).position());
		});

		return this;
	};



	function clickHandler(e){
		e.preventDefault();
		/*console.log(e.data.options.targetMenuContainerDescriptor);
		console.log('clickhandler clicked');*/
		$(e.target).toggleClass("shown").siblings().toggleClass("shown");
		$(e.data.options.targetMenuContainerDescriptor).toggleClass('shown');
		$(e.data.options.menuControls.containerDescriptor).toggleClass('shown');

		if(e.data.options.pushBody.push == true){
			if(e.data.options.pushBody.direction == 'left'){
				$(e.data.options.pushBody.pushContainerDescriptor).toggleClass(e.data.options.pushBody.leftClass);
				$(e.data.options.targetMenuContainerDescriptor).toggleClass(e.data.options.pushBody.leftClass);
				$(e.data.options.menuControls.containerDescriptor).toggleClass(e.data.options.pushBody.leftClass);
			}else if(e.data.options.pushBody.direction == 'right'){
				$(e.data.options.pushBody.pushContainerDescriptor).toggleClass(e.data.options.pushBody.rightClass);
				$(e.data.options.targetMenuContainerDescriptor).toggleClass(e.data.options.pushBody.rightClass);
				$(e.data.options.menuControls.containerDescriptor).toggleClass(e.data.options.pushBody.rightClass);
			}

			if($(e.data.options.pushBody.pushContainerDescriptor).hasClass(e.data.options.pushBody.leftClass) ||
					$(e.data.options.pushBody.pushContainerDescriptor).hasClass(e.data.options.pushBody.rightClass)	){
				//$(window).disablescroll({onlyDisableMobileSideSwipe: true});
				/*console.log("asdasnjkdnasjkdnkasndkasndj");*/
			}else{
				//$(window).disablescroll("undo");				
			}
		}
	}


	$.fn.responsive_menu.defaults = {
			targetMenuContainerDescriptor: 'nav#headernav',
			pushBody: {
				push: true,
				direction: "left",
				pushContainerDescriptor: 'body',
				baseClass: "responsive-menu-push",
				leftClass: 'responsive-menu-push-toleft',
				rightClass: 'responsive-menu-push-toright'
			},
			menuControls: {
				containerDescriptor: '#headernavmenucontrols',
				openLinkDescriptor: '#openmenulink',
				closeLinkDescriptor: '#closemenulink',
				containerClass: 'responsive-menu-controls-container'

			},
			menu: {
				menuClass: 'responsive-menu-container'
			},
			link: {
				linkclass: 'responsive-menu-link'
			}
	};

}(jQuery));
